@mixin newsletter-form-title {
  font-size: 3.2rem;

  @include media-breakpoint-up(sm) {
    font-size: 4.5rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 5.5rem;
  }
}
