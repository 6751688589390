@mixin slider-dots-wrap {
  display: flex;
  list-style: none;
  justify-content: center;

  &.bottom {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: -60px;
  }

  &.below {
    margin-top: 15px;
  }
}

@mixin slider-dot-button {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  margin-right: 9px;
  margin-left: 9px;
  display: flex;
  align-items: center;
  opacity: 0.6;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 25px;
  transition: all $default-animation-speed ease-in-out;

  @include media-breakpoint-up(sm) {
    width: 34px;
    height: 34px;
  }

  &:hover {
    transform: rotate(90deg);
    opacity: 0.8;
  }

  &.isSelected {
    transform: rotate(180deg);
    opacity: 1;
  }

  &:nth-child(4n + 1) {
    background-image: url($cloudinaryThemeCloudBaseUrl + 'images/icons/dots/dot-1.png');
  }

  &:nth-child(4n + 2) {
    background-image: url($cloudinaryThemeCloudBaseUrl + 'images/icons/dots/dot-2.png');
  }

  &:nth-child(4n + 3) {
    background-image: url($cloudinaryThemeCloudBaseUrl + 'images/icons/dots/dot-3.png');
  }

  &:nth-child(4n + 4) {
    background-image: url($cloudinaryThemeCloudBaseUrl + 'images/icons/dots/dot-4.png');
  }
}
