$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/surprise/';
@import 'styles/helpers/global_variables';

.slideWrap {
  position: relative;
  min-width: auto;
  //padding: 0 5px;
  padding-left: 5px;

  &:first-child {
    padding-left: 0;
  }

  .slideInner {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding: 0 5px;
    height: 180px;
    @include media-breakpoint-up(sm) {
      height: 320px;
    }
    @include media-breakpoint-up(md) {
      height: 360px;
    }

    img {
      height: 100%;
      width: auto;
    }

    .fullScreenButton {
      position: absolute;
      z-index: 99;
      right: 6px;
      bottom: 0;
      color: white;

      @include print-hidden;
    }
  }
}
