$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/surprise/';
@import 'styles/helpers/global_variables';

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}

.embla__dot {
  @include slider-dot-button
}
